function setEqualHeight() {
    var current_breakpoint_num = getNumSkelSize();
    $(".grid-product .grid-element-content-header-image").matchHeight();
    $(".grid-product .grid-element-title").matchHeight();
    $(".grid-product .product-options").matchHeight();
    $(".grid-product .prices-small").matchHeight();
    $(".grid-product .nakup").matchHeight();
    $(".grid-product").matchHeight();
    if (current_breakpoint_num > 2) {
        $(".prva-artikli-enako").matchHeight({
            target: $("#prva-artikli-enako"),
            byRow: true
        });
    } else {
        $(".prva-artikli-enako").matchHeight({
            remove: true,
            byRow: false
        });
    }

    $(".grid-news-item-020 .news-content-wrapper").matchHeight();
    $(".grid-news-item-020 .news-image-wrapper").matchHeight();
    $(".grid-news-item-020 article").matchHeight();
}

function cenejeSiCertifiedShop() {
    var smdWrapper = document.createElement("script"),
        smdScript;
    smdWrapper.async = true;
    smdWrapper.type = "text/javascript";
    smdWrapper.src = "https://cpx.smind.si/Log/LogData?data=" + JSON.stringify({
        Key: "Slo_3692",
        Size: "80",
        Type: "badge",
        Version: 2,
        BadgeClassName: "smdWrapperTag"
    });
    smdScript = document.getElementsByTagName("script")[0];
    smdScript.parentNode.insertBefore(smdWrapper, smdScript);
} // END cenejeSiCertifiedShop

jQuery(document).ready(function($) {
    cenejeSiCertifiedShop();
});

$(window).on("load", function() {
    setEqualHeight();
});
$(window).on("resize", function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});

$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        scroll_limit_1 = 50,
        scroll_limit = 150,
        initial_height = 100,
        target_height = 60,
        initial_padding = 10,
        target_padding = 5,
        initial_margin = 0,
        target_margin = -50,
        margin,
        logo_height,
        padding,
        line_height;
    switch (current_breakpoint) {
        case "xxlarge":
            scroll_limit_1 = 50;
            scroll_limit = 150;
            initial_height = 100;
            target_height = 60;
            initial_padding = 10;
            target_padding = 5;
            initial_margin = 0;
            target_margin = -50;
            break;
        case "xlarge":
            scroll_limit_1 = 50;
            scroll_limit = 150;
            initial_height = 100;
            target_height = 60;
            initial_padding = 10;
            target_padding = 5;
            initial_margin = 0;
            target_margin = -50;
            break;
        case "large":
            scroll_limit_1 = 50;
            scroll_limit = 150;
            initial_height = 100;
            target_height = 60;
            initial_padding = 10;
            target_padding = 5;
            initial_margin = 0;
            target_margin = -50;
            break;
        case "medium":
            scroll_limit_1 = 50;
            scroll_limit = 150;
            initial_height = 100;
            target_height = 60;
            initial_padding = 10;
            target_padding = 5;
            initial_margin = 0;
            target_margin = -50;
            break;
        case "small":
            scroll_limit_1 = 40;
            scroll_limit = 110;
            initial_height = 70;
            target_height = 50;
            initial_padding = 5;
            target_padding = 0;
            initial_margin = 0;
            target_margin = -40;
            break;
        case "xsmall":
            scroll_limit_1 = 40;
            scroll_limit = 120;
            initial_height = 50;
            target_height = 50;
            initial_padding = 5;
            target_padding = 5;
            initial_margin = 0;
            target_margin = -40;
            break;
        default:
            scroll_limit_1 = 50;
            scroll_limit = 150;
            initial_height = 100;
            target_height = 60;
            initial_padding = 10;
            target_padding = 5;
            initial_margin = 0;
            target_margin = -50;
            break;
    }
    if (current_breakpoint_num > 0) {
        if ($(window).scrollTop() === 0) {
            $("#header-wrapper").css("margin-top", "");
            logo_height = initial_height;
            padding = initial_padding;
        } else if ($(window).scrollTop() > 0 && $(window).scrollTop() <= scroll_limit_1) {
            $("#header-wrapper").css("margin-top", -$(window).scrollTop() + "px");
            logo_height = initial_height;
            padding = initial_padding;
        } else if ($(window).scrollTop() > scroll_limit_1 && $(window).scrollTop() <= scroll_limit) {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");
            logo_height = initial_height - ($(window).scrollTop() / scroll_limit) * (initial_height - target_height);
            padding = initial_padding - ($(window).scrollTop() / scroll_limit) * (initial_padding - target_padding);
        } else {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");
            logo_height = target_height;
            padding = target_padding;
        }
        $("#logo a").css("height", logo_height + "px");
        $("#logo a").css("padding-top", padding + "px");
        $("#logo a").css("padding-bottom", padding + "px");
        // $("#header-main-wrapper").css("padding-top", padding + "px");
        // $("#header-main-wrapper").css("padding-bottom", padding + "px");
    } else {
        $("#logo a").css("height", "");
        $("#logo a").css("padding-top", "");
        $("#logo a").css("padding-bottom", "");
        // $("#header-main-wrapper").css("padding-top", "");
        // $("#header-main-wrapper").css("padding-bottom", "");
    }

});
